<template>
  <div>
    <procedure-header
      v-if="loggedUser.role === 'professional'"
      :procedure-creation-enabled="procedureCreationEnabled"
      @clickCreate="isShow.create=true"
    />

    <v-data-iterator
      :items="procedureList"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
      no-data-text="Nessuna pratica disponibile"
      no-results-text="Nessuna pratica corrispondente"
    >
      <template v-slot:header>
        <v-toolbar
          dark
          color="primary"
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            dense
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Cerca"
          >
          </v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              flat
              dense
              solo-inverted
              hide-details
              :items="keys"
              prepend-inner-icon="mdi-arrow-up-down"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                small
                depressed
                color="primary"
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                small
                depressed
                color="primary"
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row class="mt-1">
          <v-col
            v-for="(procedure,idx) in props.items"
            :key="'A' + idx"
            cols="12"
          >
            <procedure-card
              no-survey-icon
              :procedure-obj="procedure"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-pagination
          v-model="page"
          :length="numberOfPages"
        ></v-pagination>
      </template>
    </v-data-iterator>
    <create-procedure
      :is-show="isShow.create"
      @close="isShow.create=false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProcedureHeader from '@/components/section-headers/procedure-header.vue'
import ProcedureCard from '@/components/cards/procedure-card.vue'
import CreateProcedure from '../../components/modals/create-procedure.vue'

export default {
  name: 'Index',
  components: {
    ProcedureHeader,
    CreateProcedure,
    ProcedureCard,
  },
  data() {
    return {
      isShow: {
        create: false,
      },
      search: '',
      filter: {},
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      sortBy: 'created_at',
      keys: [
        { text: 'Nome pratica', value: 'title' },
        { text: 'Data creazione', value: 'created_at' },
      ],
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Le mie pratiche',
          to: '/procedures',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'procedureList', 'productFunctionalities']),
    numberOfPages() {
      return Math.ceil(this.procedureList.length / this.itemsPerPage)
    },
    procedureCreationEnabled() {
      return this.productFunctionalities.canCreateProcedure.status
    },
  },
  mounted() {
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
  },
}
</script>

<style scoped>
  .v-select__selections {
      color: red !important;
  }
</style>
