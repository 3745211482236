<template>
  <div
    class="d-flex"
  >
    <v-spacer />
    <v-btn
      color="primary"
      class="my-3 hidden-xs-only"
      :disabled="!procedureCreationEnabled"
      @click="clickCreate"
    >
      <v-icon>mdi-plus</v-icon>
      Crea una pratica
    </v-btn>
    <v-btn
      color="primary"
      class="my-3 hidden-sm-and-up"
      block
      @click="clickCreate"
    >
      <v-icon>mdi-plus</v-icon>
      Crea una pratica
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProcedureHeader',
  props: {
    procedureCreationEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
  },
  methods: {
    clickCreate() {
      this.$emit('clickCreate')
    },
  },
}
</script>
