<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Crea una Pratica</span>
        </v-card-title>
        <v-card-text>
          <v-stepper
            v-model="step"
            flat
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="step > 1"
                step="1"
              >
                Crea o importa
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="step > 2"
                step="2"
              >
                Importa documenti
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
              >
                Invita utente
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  ref="form"
                  @submit.prevent="create"
                >
                  <v-row class="ma-0">
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.title"
                        label="Titolo"
                        outlined
                        required
                        dense
                        :rules="[formRules.required]"
                        hint="Il titolo della pratica"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        v-model="form.description"
                        label="Descrizione"
                        rows="3"
                        dense
                        outlined
                        hint="Descrizione estesa della pratica"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.reference"
                        label="Riferimento interno"
                        outlined
                        required
                        dense
                        hint="Il riferimento interno aziendale"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-menu
                        ref="menu_date"
                        v-model="menu_date"
                        :close-on-content-click="false"
                        :return-value.sync="menu_date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.expiry"
                            label="Data di scadenza prevista"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.expiry"
                          no-title
                          scrollable
                          locale="it-IT"
                          :first-day-of-week="1"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu_date = false"
                          >
                            Chiudi
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu_date.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        class="ma-2"
                        :disabled="loading"
                        @click="closeModalNewProcedure"
                      >
                        Chiudi
                      </v-btn>
                      <v-btn
                        v-if="procedureTemplateList.length>0"
                        class="ma-2"
                        color="info"
                        :disabled="loading"
                        @click="importModal=true"
                      >
                        Importa
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="ma-2"
                        :loading="loading"
                        @click.prevent="create"
                      >
                        Crea
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form
                  ref="formDefaultDoc"
                  @submit.prevent
                >
                  <v-row class="ma-0">
                    <p class="font-weight-bold">
                      Documenti standard
                    </p>
                    <v-col
                      v-for="(item,idx) in defaultDocumentsList"
                      :key="idx"
                      class="pt-0 pb-0"
                      cols="12"
                    >
                      <v-switch
                        v-model="formDefaultDoc[item.id]"
                        :label="item.title"
                        false-value="no"
                        true-value="yes"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="optionalImportCompanyDocs.length>0"
                    class="ma-0 mt-1"
                  >
                    <p class="font-weight-bold">
                      Documenti aziendali opzionali
                    </p>
                    <v-col
                      v-for="(item,idx) in optionalImportCompanyDocs"
                      :key="'B'+idx"
                      class="pt-0 pb-0"
                      cols="12"
                    >
                      <v-switch
                        v-model="optionalDocs[item.id]"
                        :label="item.title"
                        false-value="no"
                        true-value="yes"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="autoImportCompanyDocs.length>0"
                    class="ma-0 mt-1"
                  >
                    <p class="font-weight-bold">
                      Documenti aziendali importati in automatico
                    </p>
                    <v-col
                      v-for="(item,idx) in autoImportCompanyDocs"
                      :key="'A'+idx"
                      class="pt-0 pb-0"
                      cols="12"
                    >
                      <v-switch
                        disabled
                        :label="item.title"
                        input-value="true"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row
                    class="ma-0 mt-1"
                  >
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        color=""
                        class="ma-2"
                        :disabled="loading"
                        @click.prevent="step=3"
                      >
                        Salta
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="ma-2"
                        :loading="loading"
                        @click.prevent="importDefaultDocs"
                      >
                        Avanti
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-form
                  ref="formInvite"
                  @submit.prevent
                >
                  <v-row class="ma-0">
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="formInvite.email"
                        label="Email"
                        type="email"
                        outlined
                        required
                        dense
                        :rules="[formRules.required,formRules.emailRules]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-select
                        v-model="formInvite.role"
                        label="Role"
                        outlined
                        required
                        dense
                        :items="[{'text':'Utente','value':'user'}]"
                        :rules="[formRules.required]"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        class="ma-2"
                        :disabled="loading"
                        @click="goToProcedure(procedure_id);closeModalNewProcedure();"
                      >
                        Invita in seguito
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="ma-2"
                        :loading="loading"
                        @click.prevent="invite"
                      >
                        Invita ora
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="procedureTemplateList.length>0"
      v-model="importModal"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Importa da Template</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formImport"
            @submit.prevent
          >
            <v-text-field
              v-model="formImport.title"
              label="Titolo"
              outlined
              required
              dense
              :rules="[formRules.required]"
            ></v-text-field>
            <v-textarea
              v-model="formImport.description"
              label="Descrizione"
              rows="3"
              dense
              outlined
            ></v-textarea>
            <v-menu
              ref="menu_date2"
              v-model="menu_date2"
              :close-on-content-click="false"
              :return-value.sync="menu_date2"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formImport.expiry"
                  label="Scadenza prevista"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formImport.expiry"
                no-title
                scrollable
                locale="it-IT"
                :first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu_date2 = false"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu_date2.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-form>
          <v-card
            v-for="(item,idx) in procedureTemplateList"
            :key="idx"
            class="mb-1"
          >
            <v-card-text class="d-flex justify-space-between">
              <div>{{ item.title }}</div>
              <div>
                <v-btn
                  small
                  color="info"
                  :loading="loading"
                  @click.prevent="importTemplate(item.id)"
                >
                  Importa
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProcedureCreate',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,
      form: {},
      formInvite: { role: 'user' },
      formImport: {},
      formDefaultDoc: {},
      optionalDocs: {},
      importModal: false,
      procedure_id: null,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu_date: false,
      menu_date2: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'procedureTemplateList', 'companyDocumentsList', 'defaultDocumentsList']),
    autoImportCompanyDocs() {
      if (this.companyDocumentsList.length > 0) {
        return this.companyDocumentsList.filter(f => f.flag_auto_import === 'yes')
      }

      return {}
    },
    optionalImportCompanyDocs() {
      if (this.companyDocumentsList.length > 0) {
        return this.companyDocumentsList.filter(f => f.flag_auto_import === 'no')
      }

      return {}
    },
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const response = await this.$api.createProcedure(this.form)
          if (response.data.status === 'success') {
            this.procedure_id = response.data.procedure_id
            this.loading = false
            this.step = 2
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'success' })
            await this.$store.dispatch('getProcedures')
            await this.$store.dispatch('setProductPercentages')
            await this.$store.dispatch('setProductFunctionalities')
          } else {
            this.loading = false
            this.procedure_id = null
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'error' })
          }
        } catch (e) {
          this.loading = false
          this.procedure_id = null
          await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
        }
      }
    },
    async importDefaultDocs() {
      try {
        this.loading = true
        const responseDefault = await this.$api.importDefaultDocsIntoProcedure(this.procedure_id, this.formDefaultDoc)
        const responseAuto = await this.$api.getAutoImportDocuments(this.procedure_id)
        const responseOptional = await this.$api.optionalImportDocuments(this.procedure_id, { documents: this.optionalDocs })

        if (responseDefault.data.status === 'success' && responseAuto.data.status === 'success' && responseOptional.data.status === 'success') {
          await this.$store.dispatch('getProcedure', this.procedure_id)
          await this.$store.dispatch('setProductPercentages')
          await this.$store.dispatch('setProductFunctionalities')
          this.loading = false
          this.step = 3
          await this.$store.dispatch('set_alert', { msg: 'Documenti importati con successo', type: 'success' })
        } else {
          await this.$store.dispatch('set_alert', { msg: responseAuto.data.message, type: 'error' })
          this.loading = false
        }
      } catch (e) {
        this.loading = false
        await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
      }
    },
    async invite() {
      if (this.$refs.formInvite.validate() && this.procedure_id) {
        try {
          this.loading = true
          const respInvite = await this.$api.inviteUser(this.procedure_id, this.formInvite)
          if (respInvite.data.status === 'success') {
            await this.$store.dispatch('getProcedures')
            this.closeModalNewProcedure()
            await this.$store.dispatch('set_alert', { msg: respInvite.data.message, type: 'success' })
            await this.goToProcedure(this.procedure_id)
          } else {
            await this.$store.dispatch('set_alert', { msg: respInvite.data.message, type: 'error' })
          }
        } catch (e) {
          this.closeModalNewProcedure()
          await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
        }
      }
    },
    async importTemplate(id) {
      if (this.$refs.formImport.validate()) {
        try {
          this.loading = true
          const respImport = await this.$api.importProcedureTemplate(id, this.formImport)
          if (respImport.data.status === 'success') {
            this.procedure_id = respImport.data.procedure_id
            this.importModal = false
            this.step = 2
            this.loading = false
            await this.$store.dispatch('set_alert', { msg: respImport.data.message, type: 'success' })
            await this.$store.dispatch('getProcedures')
          } else {
            await this.$store.dispatch('set_alert', { msg: respImport.data.message, type: 'error' })
            this.loading = false
          }
        } catch (e) {
          this.loading = false
          this.procedure_id = null
          await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
        }
      }
    },
    closeModalNewProcedure() {
      this.step = 1
      this.loading = false
      this.procedure_id = null
      this.$refs.form.reset()
      this.$refs.formInvite.reset()
      this.$emit('close')
    },
    goToProcedure(pid) {
      this.$router.push({ name: 'procedureView', params: { id: pid } })
    },
  },
}
</script>

<style scoped>

</style>
